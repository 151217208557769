@charset "utf-8";
/* CSS Document */

.bttm {
  width: 1000px;
  min-width: 700px;
  text-align: center;
  margin: auto;
  bottom: 0;
  margin-top: 25px;
}
.top {
    /*background-image:  url("https://portal.citybook.co.il/bgtop.jpg");*/
	background-color:#fff7ec;
	background: url(https://portal.citybook.co.il/texture.png) center no-repeat;
    background-size:     cover;   
    background-repeat:   no-repeat;
    background-position: center center; 
	text-align:center;
	Border-bottom: 7px solid #C18A47
}
.Fotbanner {width:100%}
.FootB {border-top: 7px solid #C18A47; background-color: #fff7ec}

.imgbttm {
  width: 100%;
  height: auto;
  bottom: 0;
}

.details {
  font-size: 23px;
  line-height: 1.8em;
  color: #4c0870;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

input, select {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-family: "Open Sans",sans-serif;
  font-size: 18px;
  color: #8d8d8d;
}
select {
   width: 250px;
   margin-left: 20px;
}
input[type="checkbox"] {
  width: 30px;
  height: 30px;
  margin: 0;
  margin-right: 10px;
  vertical-align: top;
}
.giftext .radio {
    margin-left: -57px;
    position: relative;
    top: 51px;
}

form {
  width: 900px;
  margin: auto;
  text-align: left;
}

h3 {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  color: #135872;
}

.giftext {
width: 700px;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5em;
  color: #135872;
  display: inline-grid;
}

.giftext2 {
  font-size: 23px;
  font-weight: 500;
  line-height: 3em;
  color: #135872;
}

.radio {
  width: 2em;
  height: 2em;
  background-color: rgba(255, 255, 255, 1);
  vertical-align: middle;
}

p .hebrew {
  font-size: 1.1em;
  font-family: "Assistant", sans-serif;
}

.hebrew3 {
  font-size: 1.2em;
  text-align: right;
  direction: rtl;
  font-family: "Assistant", sans-serif;
}

.hebrew2 {
  font-size: 1.2em;
  padding-left: 100px;
  direction: rtl;
  font-family: "Assistant", sans-serif;
}

.strong {
  font-size: 20px;
  margin-bottom: 0px;
}
.giftimg {
  border-bottom: 1px solid;
  margin-bottom: 35px;
  overflow: auto;
  padding-bottom: 35px;
  padding-top: 0px;
  width: 100%;
}

.giftimg2 {
  display: flex;
  padding-bottom: 35px;
  padding-top: 25px;
}

.giftimg4 {
  border-bottom: 1px solid;
  margin-bottom: 35px;
  display: flex;
  padding-bottom: 35px;
  padding-top: 25px;
}

.linen {
  width: 49%;
  margin: auto;
  display: inline;
  text-align: center;
  float: left;
}
.gift {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 8px;
  margin-top: 5px;
  /*max-width: 630px;*/
}

.gift:hover {
 /* opacity: 0.7;*/
}

.amount {
  width: 35px;
  padding: 2px 6px;
  margin-left: 8px;
  text-align: center;
}

textarea {
  width: 100%;
  margin-bottom: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #8d8d8d;
}

.submit {
  width: 50%;
  padding: 15px;
  font-size: 25px;
  background-color: #2b0042;
  border-style: none;
  color: rgba(255, 255, 255, 1);
  margin-left: 25%;
  text-transform: uppercase;
  margin-bottom: 25px;
  cursor: pointer;
  transition: all 0.5s;
}
button.submit{
  margin-left: auto;
}

.submit:hover,
.submit:active,
.submit:focus {
  background-color:#8E57A3;
}

.smaller {
  font-size: 0.8em;
}

form p {
  line-height: 1.5em;
  /*width: 35%;*/
  color: #2b0042;
  margin-left: 50px;
  -webkit-margin-start: 50px;
  font-size: 0.85em;
  display: inline;
  display: -webkit-inline-box;
  font-family: "Open Sans", sans-serif;
}

.phebrew {
  font-size: 1.1em;
  direction: rtl;
  text-align: right;
  line-height: 1.2;
  vertical-align: top;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

a:-webkit-any-link {
  text-decoration: none;
}

a:-webkit-any-link:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
#alertSelectChildren,
#alertAmt,
#alertSelectAdult,
#alertamtA,
#alertamtB,
#alertamtC {
  font-size: 1.6em;
  color: #e10e12;
  width: 100%;
  margin-bottom: 4px;
  text-align: center;
}
.childrenbeddingcontent {
  overflow: auto;
}

#loadingt {
  width: 50%;
  margin: auto;
  border-bottom: 1px solid rgba(153, 102, 0, 1);
  margin-bottom: 35px;
  overflow: auto;
  padding-bottom: 35px;
  padding-top: 25px;
  color: #2b0042;
}

.bcolor {
  color: #987238;
}

.colorimg {
  display: inline;
  width: 20px;
}

li {
  list-style-position: inside;
}

::marker {
  font-size: 100px;
  color: #C18A47;
  font-weight: 700;
}

.m50 {
  margin-left: 50px;
}

.first-row {
  color: #987238;
  margin-left: 0;
  width: 100%;
  font-weight: 600;
}

.first-row2 {
  margin-left: 0;
  font-size: 0.6em;
  width: 100%;
  font-weight: 700;
  color: #987238;
}

.first-row3 {
  color: #2b0042;
  margin-left: 0;
  width: 100%;
  font-weight: 600;
}

.bttn-more {
  background-color: rgba(123, 94, 165, 1);
  width: 350px;
  height: 45px;
  cursor: pointer;
  text-align: center;
  margin: auto;
  padding-top: 3%;
  border: 1px solid rgba(76, 53, 102, 1);
  color: #ffffff;
}

.shops {
  width: 100%;
}

.shops-text {
  /*white-space: pre-line;*/
  display: block;
}

.shops-text-container {
  width: 100%;
  display: block;
}

.goldc {
  color: #2b0042;
}

.pcolor {
  color: #2b0042;
}

.rcolor {
  color: rgba(206, 61, 90, 1);
}

.picolor {
  color: #7e846a;
}
.linka {
  color: #966e14;
}

.snif-div {
  border: 1px #966e14 solid;
  padding: 15px;
  width: 26%;
  float: right;
  margin: 15px 0px 15px 30px;
  text-align: right;
  color: #2b0042;
  font-family: "Assistant", sans-serif;
}

.city {
  font-weight: 700;
}

.leftside {
  width: 70%;
  float: left;
  margin-top: 35px;
}

.rightside {
  width: 30%;
  float: left;
}

.fright {
  float: right;
}

.last-row {
  font-size: 1.2em;
  font-weight: 700;
  color: #987238;
  text-align: center;
  width: 100%;
  margin-left: 5.5%;
}

.website {
  direction: rtl;
}

.rtl {
  direction: rtl;
}

.amount {
  font-weight: 700;
  font-size: 50px;
  color: #966e14;
}

.p90 {
  padding-left: 90px;
}
.for {
  font-size: 21px;
  color: #966e14;
}

.gcolor {
  color: #2b0042;
}

.choosebook {
  width: 100%;
  direction: rtl;
  text-align: right;
}

.heb {
  font-family: "Assistant", sans-serif;
}

.GiftItem{
                padding-bottom: 40px;
                border-bottom: 2px solid #2b0042;
	margin-bottom: 30px;
}

.d-title{
font-weight: 600; 
                color: #135872;
                text-transform: uppercase;
}
/*#root {padding-bottom: 100px;}*/




.uppercase{
  text-transform: uppercase;
}

.text-success{
  font-size: 14px;
}
.inst-text {padding: 40px 0 0 0}

.wlcm {font-size: 3em;
    font-weight: 500; text-align:center;margin-bottom: 0;}

/* FOR SIYUM 2022 */
Body{
Background-color: #FFF7EC;
}

.container{
/*Background-color: #FFF7EC;*/
Margin-top:-65px;
Padding-top:25px;
}

.d-title, .giftext, h3 {
Color: #135872;
}

.submit{
Background-color: #C18A47;
Color:#ffffff;
}
.submit:active, .submit:focus, .submit:hover{color:#fff}

.submit:active, .submit:focus, .submit:hover{
Background-color: #8E5A24;
Color:#135872;
}

.GiftItem{
border-bottom: 2px solid #135872;
}

.dontsh {display:none;}
/* END SIYUM */
