@charset "utf-8";
/* CSS Document */

body, html{
	scroll-behavior: smooth;
}

body{
	margin: 0;
	padding: 0;
/*background-image: linear-gradient(45deg, #1FB4EF, #E14AC0, #F5B449);
*/	background-color: #f4e4bd;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}

.wbg{
	background-color: rgba(255,255,255,1.00);
	width: 100%;
}

.bigcontainer{
	width:100%;
	max-width: 1000px;
	margin: auto;
	padding: 0;
	margin-top: 65px;
}


.container{
	width: 100%;
	margin: auto;
	background: #FFF7EC;
}

.topbanner{
	/*width: 100%;
	height: auto;
	margin-top: -80px;*/
}

.inst-text{
	text-align: center;
	font-size: 25px;
}

.hebrew{
	direction: rtl;
	/*font-family: 'Assistant', sans-serif;*/
	font-size: 27px;
	   
}
.bld {font-weight: bold;}
.img-screen{
	width: 50%;
	margin-left: 25%;
	border: 1px solid #000;
}

.top15{
	margin-top: 25px;
}

.top30{
	margin-top: 25px;
	padding-top: 25px;
}

.border-t{
	border-top: 1px solid #515644;
	width: 70%;
		margin-left:15%;
}

.goldc{
	color:#515644;
}

.bold{
	font-weight: 600;
}

.link, .link:hover{
	color: #515644 !important;
	cursor: pointer
}

.mb-0 {margin-bottom: 0}
.mt-0 { margin-top: 0;}
.title{font-size: 1.25em;}

h2 {line-hight: 1.5}

/*#root {
    padding-top: 40px;
    border-right: 14px solid #B29142;
	border-left: 14px solid #B29142;
	    margin-top: -5px;
}*/

/* for siyum 2022 */
Body{
Background-color: #003f47;
}

/* end siyum */ 

